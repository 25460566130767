import { formatMoney } from "./currency";
import { types as sdkTypes } from './sdkLoader';
const { Money } = sdkTypes;

export const defaultVatPercentage = 25.5;
export const MaxCartSize = 75;

export const isLitingInUserCart = ({ cart = [], listingId }) => {
    if (cart.length == 0) {
        return false;
    } else {
        const neededCartData = cart.find(i => i.listingId == listingId);
        if (neededCartData) {
            return true;
        } else {
            return false;
        };
    };
};

const getTotalForSpecificLineItem = (lines, line, intl) => {
    let total = 0;
    let lineExists = false;

    lines.map(l => {
        l.txLineItems.filter(t => t.code == line).map(t => {
            total += t.lineTotal.amount;
            lineExists = true;
        });
    });

    return {
        total: formatMoney(intl, new Money(total, 'EUR')),
        lineExists,
    };
};

const getFullTotal = (lines, intl) => {
    let total = 0;

    lines.map(l => {
        if (l.txPayinTotal) {
            total += l.txPayinTotal.amount;
        } else {
            total += l.txPayoutFullTotal.amount;
        };
    });

    return formatMoney(intl, new Money(total, 'EUR'));
};

export const getCartBreakdown = (neededLineItems, type, intl, marketplaceName) => {
    const isRent = type == 'rent';
    const successLines = neededLineItems.filter(l => l.success);

    const subTotal = getTotalForSpecificLineItem(successLines, `${isRent ? 'line-item/day' : 'line-item/item'}`, intl);
    const vat0 = getTotalForSpecificLineItem(successLines, "line-item/VAT-0% (Out of scope of tax)", intl);
    const vatPercent = getTotalForSpecificLineItem(successLines, `line-item/VAT-${defaultVatPercentage}%`, intl);
    // const pickup = getTotalForSpecificLineItem(successLines, 'line-item/pickup-fee', intl);

    const providerCommission = getTotalForSpecificLineItem(successLines, 'line-item/provider-commission', intl);
    const marketplaceFee = getTotalForSpecificLineItem(successLines, `line-item/Marketplace-fee-VAT-${defaultVatPercentage}%`, intl);

    const fullTotal = getFullTotal(successLines, intl);

    let lineItems = [];

    if (subTotal.lineExists) {
        const subTotalLabel = intl.formatMessage({ id: 'Cart.LineItems.subtotal' });
        lineItems.push({ text: subTotalLabel, price: subTotal.total });
    };
    const vatLabel = intl.formatMessage({ id: 'Cart.LineItems.vat' });
    if (vat0.lineExists) {
        lineItems.push({ text: `${vatLabel} 0%`, price: vat0.total });
    };
    if (vatPercent.lineExists) {
        lineItems.push({ text: `${vatLabel} ${defaultVatPercentage}%`, price: vatPercent.total });
    };
    // if (pickup.lineExists) {
    //     lineItems.push({ text: "Pickup fee", price: pickup.total });
    // };

    if (providerCommission.lineExists) {
        lineItems.push({
            text: intl.formatMessage(
                { id: 'OrderBreakdown.commission' },
                { marketplaceName },
            ),
            price: providerCommission.total,
        });
    };
    if (marketplaceFee.lineExists) {
        lineItems.push({
            text: intl.formatMessage(
                { id: "OrderBreakdown.marketplaceVat" },
                { vatPercentage: defaultVatPercentage }
            ),
            price: marketplaceFee.total,
        });
    };

    const totalLabel = intl.formatMessage({ id: 'Cart.LineItems.total' });
    return [...lineItems, { text: totalLabel, price: fullTotal }];
};
