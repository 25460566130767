import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const {
    lineItems,
    code,
    intl,
    // isUsingSpecialRentalPricing,
    booking,
    isCartTx,
  } = props;

  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = isNightly
    ? 'OrderBreakdown.baseUnitNight'
    : isDaily
      ? 'OrderBreakdown.baseUnitDay'
      : isHourly
        ? 'OrderBreakdown.baseUnitHour'
        : 'OrderBreakdown.baseUnitQuantity';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  const { start, end } = booking?.attributes || {};

  const endDate = new Date(end)
  const startDate = new Date(start)
  // Calculating the time difference
  // of two dates
  let Difference_In_Time =
    endDate.getTime() - startDate.getTime();

  // Calculating the no. of days between
  // two dates
  let Difference_In_Days =
    Math.round
      (Difference_In_Time / (1000 * 3600 * 24));

  return quantity && total && unitPurchase.lineTotal?.amount != 0 ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {isCartTx ? <FormattedMessage id="OrderBreakdown.cartSubtotal" />
        : isDaily ? <FormattedMessage
          id={`OrderBreakdown.numOf${Difference_In_Days > 1 ? "Days" : "Day"}`}
          values={{ num: Difference_In_Days }}
        />
        : <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
      }
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
