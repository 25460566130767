import React from 'react'
import { string } from 'prop-types';
import css from './FieldSubDescription.module.css';

const FieldSubDescription = (props) => {
    const { subDescription, style = {} } = props

    return <>
        {subDescription && subDescription !== "N/A" ? <span
            className={css.subDescription}
            style={style}
        >
            {subDescription}
        </span> : null}
    </>
}
FieldSubDescription.defaultProps = {
    subDescription: null
};

FieldSubDescription.propTypes = {
    subDescription: string,
};


export default FieldSubDescription